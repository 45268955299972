import Controller from './../base_controller';
import match from '../../utils/match';

export default class extends Controller {
  static targets = ['query', 'template', 'row', 'list', 'rows', 'texts'];

  initialize() {
    this.textsIdentifier = 'texts';
    this.query = '';
    this.type = this.identifier.replace(/filter-standard-|-text/g, '');
    this.rowIdentifier = `row-${this.type}`;
    this.filters = [];

    this.afterFullLoad(() => {
      this.all = JSON.parse(this.data.get(this.textsIdentifier));

      Array.from(this.rowsTarget.children).forEach((element) => {
        const clickHandler = () => {
          try {
            const area = element.querySelector('.source-text') || element.querySelector('textarea');
            const editor = window.tinyMCE.editors[area.id];

            editor.on('click', () => { this._setRow(element) });
          } catch {
            setTimeout(clickHandler, 100);
          }
        };
        clickHandler();
      });
      this._setRow(this.rowsTarget.firstElementChild);
    });
  }

  addText(newOrUpdatedText) {
    const existingText = this.findById(newOrUpdatedText.id);

    if (existingText) {
      const existingIndex = this.all.indexOf(existingText);
      this.all.splice(existingIndex, 1, newOrUpdatedText);
    } else {
      this.all.push(newOrUpdatedText);
    }
    this.filter();
  }

  addFilter(filter) {
    this.filters = [...this.filters, filter];
  }

  changeCurrentRow(event) {
    const target = event.type === 'DOMNodeInserted' ? event.target : event.currentTarget;
    const row = target.closest('tr.fields, [data-controller="row-source"]');
    // const row = target.closest('[data-controller="row-source"]');
    this.afterFullLoad(() => {
      const area = row.querySelector('textarea') || row.querySelector('.source-text');
      const editor = window.tinyMCE.editors[area.id];

      setTimeout(() => (editor ? window.tinyMCE.setActive(editor) : null), 100);
      this._setRow(target);
    });
  }

  emptyQueryInput() {
    if (!this.hasQueryTarget) return;
    this.queryTarget.value = '';
  }

  filterByQuery() {
    this.current = this.all.filter(this.keywordFilter.bind(this));
    if (this.rowController) this.rowController.showOrHideSave();
    this.refreshListHTML();
  }

  filter() {
    if (this.hasQueryTarget && this.queryTarget.value) {
      this.filterByQuery();
      return;
    }

    this.current = this.all.filter(text => this.filters
      .every(filterFn => filterFn.call(this, text)));
    if (this.rowController) this.rowController.showOrHideSave();
    this.refreshListHTML();
  }

  findById(id) {
    return this.all.find(text => parseInt(text.id, 10) === parseInt(id, 10));
  }

  keywordFilter(standardText) {
    this.query = this.queryTarget.value;
    if (!this.query || this.query === '') return true;

    if (!standardText.keywords) return false;
    const keywordList = standardText.keywords.split(/\s*,\s*/).map(s => s.toLowerCase());
    return keywordList.some(keyword => match(keyword, this.query));
  }

  nameFilter(standardText) {
    this.query = this.queryTarget.value;

    if (!this.query || this.query === '') return true;

    return match(standardText.name, this.query);
  }

  async refreshListHTML() {
    this.listTarget.innerHTML = '';
    const injectIdentifier = this.identifier.replace('filter', 'inject');

    this.listTarget.innerHTML = this.templateTarget.innerHTML.repeat(this.current.length);
    await new Promise(resolve => setTimeout(resolve, 10));
    Array.from(this.listTarget.getElementsByTagName('li')).forEach((childNode, i) => {
      var controller = this.getController(childNode, injectIdentifier);
      if (controller) {
        controller.setText(this.current[i]);
      }
    });
  }

  removeText(removableText) {
    const existingText = this.findById(removableText.id);
    const existingIndex = this.all.indexOf(existingText);
    this.all.splice(existingIndex, 1);
    this.filter();
  }

  _setRow(row) {
    if (row && row === this.row) return;

    this.row = row;
    this.rowController = this.getController(row, this.rowIdentifier);

    this.emptyQueryInput();
    this.filter();
  }
}
