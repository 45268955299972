import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  save() {
    window.onbeforeunload = null;

    window.tinyMCE.editors.forEach((editor) => {
      if (this.element.querySelector('form') !== editor.formElement) return;

      const textarea = editor.targetElm.parentElement.querySelector('textarea');
      textarea.value = editor.getContent();
    });
  }
}
