import Controller from './../base_controller';

export default class extends Controller {
  connect() {
    this.modal = document.getElementById('edit-standard-text-controller');
    this.afterFullLoad(() => {
      this.editModalController = this.getController(this.modal, this.identifier.replace('inject', 'save'));
      this.filterController = this.findController(this.identifier.replace('inject', 'filter'));
    });
  }

  edit(event) {
    event.preventDefault();
    this.editModalController.setText(this.text);
    this.editModalController.setModalVisibility('show');
  }

  inject(event) {
    event.preventDefault();
    this.filterController.rowController.injectText(this.text.value);
  }

  setText(text) {
    this.text = text;
    this.setContent();
  }
}
