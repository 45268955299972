import Controller from './base_controller';

export default class extends Controller {
  static targets = ['text', 'show', 'project'];

  initialize() {
    this.types = JSON.parse(this.data.get('types'));
    this.general = Object.values(JSON.parse(this.data.get('general')));
    this.asbestos = Object.values(JSON.parse(this.data.get('asbestos')));
    this.demolition = Object.values(JSON.parse(this.data.get('demolition')));
    this.toggleRisk();
  }

  toggleRisk() {
    if (this.types.length === 1) {
      this.setToProject(this.types[0]);
      return;
    }

    const value = parseInt(this.textTarget.value, 10);
    if (this.general.includes(value)) {
      this.showTarget.classList.remove('hide');
      return;
    }

    this.showTarget.classList.add('hide');
    this.setToProject(this.asbestos.includes(value) ? 'asbestos' : 'demolition');
  }

  setToProject(projectType) {
    this.projectTargets.forEach((projectElem) => {
      projectElem.checked = projectElem.value === projectType;
    });
  }
}
