import Controller from './../base_controller';

export default class extends Controller {
  static targets = ['text'];

  initialize() {
    this.texts = JSON.parse(this.data.get('texts'));
    this.afterFullLoad(() => {
      initTinymce();
      // window.TinyMCERails.initialize('default', {});
    });
  }

  setText(event) {
    const text = this.texts.find(riskText => riskText.name === event.target.value);
    if (!text) return;

    const editorId = event.target.closest('tr').querySelector('textarea').id;
    this.afterFullLoad(() => {
      window.tinyMCE.editors[editorId].setContent(text.value);
    });
  }
}
