import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  /*
    The afterFullLoad function allows for controllers to attach to childControllers.
    We set a Timeout to wait for childcontrollers to have been loaded,
    Since there is no API for that yet in Stimulus, see:

    https://discourse.stimulusjs.org/t/cant-load-child-controller-by-target/381
    https://github.com/stimulusjs/stimulus/issues/201#issuecomment-435520499

  */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["afterFullLoad"] }] */
  afterFullLoad(fn) {
    setTimeout(fn, 100);
  }

  findController(identifier) {
    return this.application.controllers.find(c => c.identifier === identifier);
  }

  getController(elem, identifier) {
    return this.application.getControllerForElementAndIdentifier(elem, identifier);
  }
}
