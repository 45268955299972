import InjectStandardTextController from './inject_standard_base_text_controller';

export default class extends InjectStandardTextController {
  static targets = ['value', 'risk'];

  static modeOfRemovalAbbtreviations = {
    'Containment': 'CO',
    'Glovebag': 'GB',
    'Open Lucht': 'OL',
    'Direct Verpakken': 'DV',
  }

  setContent() {
    let variant;
    if (this.text.variant[0] === '[') {
      variant = JSON.parse(this.text.variant).map(i => `'${i}'`).join(', ');
    } else {
      variant = this.text.variant // eslint-disable-line
    }
    this.valueTarget.textContent = `${this.text.material} - ${variant} - `;
    this.riskTarget.textContent = `RK ${this.text.risk_class} ${this.constructor.modeOfRemovalAbbtreviations[this.text.mode_of_removal]}`;
  }
}
