import RowBaseController from './row_base_controller';

export default class extends RowBaseController {
  static targets = [...RowBaseController.baseTargets, 'inject'];

  injectText(value) {
    const editor = window.tinyMCE.editors[this.injectTarget.id];
    editor.setContent(editor.getContent() + value);
  }
}
