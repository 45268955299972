import SaveStandardTextController from './save_standard_base_text_controller';

export default class extends SaveStandardTextController {
  static targets = [...SaveStandardTextController.baseTargets, 'material', 'keywords', 'value', 'variant', 'mode_of_removal', 'risk_class'];

  connect() {
    super.connect();
    setTimeout(() => {
      this.setContent();
    }, 100);
  }

  setContent() {
    this.afterFullLoad(async () => {
      this.materialTarget.value = this.text.material || '';

      if (this.keywordsTarget.__vue__) {
        this.keywordsTarget.__vue__.items = this.string2chips(this.text.keywords || '');
      }
      if (this.variantTarget.__vue__) {
        this.variantTarget.__vue__.items = this.string2chips(this.text.variant || '');
      }
      const tempTarget = this.mode_of_removalTargets
        .find(({ value }) => value === this.text.mode_of_removal);
      if (tempTarget !== undefined) {
        tempTarget.checked = true;
      }
      const riskClassTarget = this.risk_classTargets.find(({ value }) => value === this.text.risk_class);
      if (riskClassTarget) riskClassTarget.checked = true;

      this.valueTarget.value = this.text.value || '';
      await initTinymce(this.valueTarget);
      window.tinyMCE.editors[this.valueTarget.id]?.setContent(this.text.value || '');
    });
  }

  string2chips(string) { // eslint-disable-line
    if (string[0] === '[') {
      return JSON.parse(string);
    }
    return (string || '').split(/\s*,\s*/).filter(Boolean); // eslint-disable-line
  }
}
