import "@hotwired/turbo-rails"
import Vue from 'vue';
import antInputDirective from 'ant-design-vue/es/_util/antInputDirective';

import '../directives/v-input'
import 'controllers';
import '~/polyfill.stimulus';

Vue.use(antInputDirective);

const componentFiles = import.meta.glob('@/components/**/*.vue');
const componentName = key => `wp${key.split('.')[0].replace(/\//g, '-').replace('components-', '')}`;

const components = Object.assign(...Object.entries(componentFiles).map(([key, loader]) => {
  return {
    [componentName(key)]: loader,
  }
}));

const filters = {
  address(record) {
    const street = [record.street, record.houseNr, record.addition].filter(Boolean).join(' ');
    return [street, record.zipcode, record.city].filter(Boolean).join(', ');
  },
};

// eslint-disable-next-line func-names
const mountVue = function (el) {
  if (el.classList.contains('mounted')) return;
  el.classList.add('mounted');
  new Vue({ el, components, filters }); // eslint-disable-line
};

window.mountVue = mountVue;

let vueElements = [...document.getElementsByClassName('mount-vue')];
document.addEventListener('turbo:load', () => {
  console.log('turbo:load');
  vueElements = [...document.getElementsByClassName('mount-vue')];
  vueElements.forEach((el) => {
    mountVue(el);
  });
});

vueElements.forEach((el) => {
  mountVue(el);
});

addEventListener("turbo:submit-start", ({ target }) => {
  for (const field of target.elements) {
    field.disabled = true
  }
})

window.loadAceEditor = async () => {
  try {
    // eslint-disable-next-line import/extensions
    await import('ace-builds/src-noconflict/ace');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

export default mountVue;
