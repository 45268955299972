import axios from 'axios';

const api = {
  async httpCall(method, url, params = {}, headers = {}, errorFn = null) {
    try {
      const { data } = await axios.request({
        method,
        url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
        data: params,
        ...(headers.Accept === 'application/pdf' && { responseType: 'blob' }),
      });

      return data;
    } catch (error) {
      if (errorFn) {
        errorFn.call(error);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      return {};
    }
  },

  get(url, headers = {}) {
    return this.httpCall('get', url, {}, headers);
  },

  delete(url, headers = {}) {
    return this.httpCall('delete', url, {}, headers);
  },

  post(url, params, errorFn = null) {
    return this.httpCall('post', url, params, {}, errorFn);
  },

  put(url, params, errorFn = null) {
    return this.httpCall('put', url, params, {}, errorFn);
  },

  index(resource, params) {
    return this.post(`/api/${resource}`, params);
  },

  exportAll(route, params, format) {
    const mimeType = format === 'csv' ? 'text/csv' : 'application/pdf';
    return this.httpCall(
      'get',
      route,
      params,
      {
        Accept: mimeType,
      },
    );
  },

  exportOverview(resource, params, format) {
    const mimeType = format === 'csv' ? 'text/csv' : 'application/pdf';
    return this.httpCall(
      'post',
      `/api/${resource}`,
      { ...params, format },
      {
        Accept: mimeType,
      },
    );
  },
};

export default api;
