import SaveStandardTextController from './save_standard_base_text_controller';

export default class extends SaveStandardTextController {
  static targets = [...SaveStandardTextController.baseTargets, 'material', 'description', 'keywords', 'value', 'relation_id'];

  setContent() {
    this.materialTarget.value = this.text.material || '';
    this.descriptionTarget.value = this.text.description || '';
    this.keywordsTarget.__vue__.items = (this.text.keywords || '').split(/\s*,\s*/).filter(Boolean);
    this.valueTarget.value = this.text.value || '';
    this.relation_idTarget.value = this.text.relation_id || '';
  }
}
