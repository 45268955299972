import RowBaseController from "./row_base_controller";

export default class extends RowBaseController {
  static targets = [
    ...RowBaseController.baseTargets,
    "inject",
    "second_inject",
    "upper_bar",
  ];

  initialize() {
    super.initialize();
    this.addShowCondition(this.injectTarget);

    this.updateRowHeight();

    setTimeout(() => {
      this.injectTarget.addEventListener('change-wysiwyg-field', (event) => {
        this.updateFieldInput(event);
      });
      this.injectTarget.addEventListener('new-wysiwyg-field', () => {
        window.tinyMCE.editors[this.injectTarget.id].on('click', () => {
          this.filterController._setRow(this.element);
        });
      });
    }, 100);
  }

  updateFieldInput(event) {
    super.updateFieldInput(event);
    this.updateRowHeight();
  }

  injectText(value) {
    const editor = window.tinyMCE.editors[this.injectTarget.id];
    if (editor) {
      editor.setContent(editor.getContent() + value);
    } else {
      // eslint-disable-next-line func-names
      window.onbeforeunload = function () {
        return "";
      };
      this.injectTarget.innerHTML = this.injectTarget.innerHTML + value;
      const textarea =
        this.injectTarget.parentElement.querySelector("textarea");
      textarea.value += value;
    }

    this.updateRowHeight();
  }

  updateRowHeight() {
    this.afterFullLoad(() => {
      try {
        this.element.style.height = `${
          this.injectTarget.parentElement.scrollHeight +
          this.second_injectTarget.parentElement.scrollHeight +
          this.upper_barTarget.scrollHeight +
          15
        }px`;
      } catch {
        setTimeout(this.updateRowHeight, 100);
      }
    });
  }
}
