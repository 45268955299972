import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/map';
import 'core-js/features/object/assign';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'mutation-observer-inner-html-shim';
import 'eventlistener-polyfill';
import elementClosest from 'element-closest';

elementClosest(window);

/* eslint-disable */
if (typeof SVGElement.prototype.contains != 'function') {
  SVGElement.prototype.contains = function (node) {
    return this === node || this.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_CONTAINED_BY;
  }
}

/* Polyfill prepend */
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('prepend')) {
      return;
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.insertBefore(docFrag, this.firstChild);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
