import $ from 'jquery';
import Controller from './base_controller';
// import 'scripts/plugins/jquery.sticky.js';

export default class extends Controller {
  initialize() {
    setTimeout(() => {
      $(this.element).sticky({
        topSpacing: 0,
        wrapperClassName: 'sticky-wrapper',
      });
    }, 0);
  }
}
