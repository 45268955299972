import FilterStandardBaseTextController from './filter_standard_base_text_controller';

export default class extends FilterStandardBaseTextController {
  initialize() {
    super.initialize();
  }

  changeCurrentRow(event) {
    const target = event.type === 'DOMNodeInserted' ? event.target : event.currentTarget;
    this.afterFullLoad(() => {
      const area = target.querySelector('textarea') || target.querySelector('.source-text');
      const editor = window.tinyMCE.editors[area.id];

      setTimeout(() => (editor ? window.tinyMCE.setActive(editor) : null), 100);
      this._setRow(target);
    });
  }
}
