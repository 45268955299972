import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers';

const application = Application.start();

// Import regular stimulus controllers
const controllers = import.meta.glob('@/controllers/*_controller.js', { eager: true })
registerControllers(application, controllers)

// Import standard_texts controllers
const standard_texts_controllers = import.meta.glob('@/controllers/standard_texts/*_controller.js', { eager: true })
for (const controller in standard_texts_controllers) {
  const identifier = controller.replace(/.*\/(.*)_controller.js/, '$1').replace(/_/g, '-')
  application.register(identifier, standard_texts_controllers[controller].default)
}
