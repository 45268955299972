import RowBaseController from './row_base_controller';

export default class extends RowBaseController {
  static targets = [...RowBaseController.baseTargets, 'removal_explaination', 'containment_number'];

  initialize() {
    super.initialize();
    this.addShowCondition(this.hasRemovalExplanation);

    this.injectTarget = this.removal_explainationTarget;
    this.afterFullLoad(() => {
      this.setContainment(this.item.mode_of_removal);
      // this.updateRowHeight();

      this.removal_explainationTarget.addEventListener('click', () => {
        window.initTinymce(this.removal_explainationTarget);
        // this.updateRowHeight();
      });

      this.removal_explainationTarget.addEventListener('change-wysiwyg-field', (event) => {
        this.updateFieldInput(event);
        // this.updateRowHeight();
      });
      this.removal_explainationTarget.addEventListener('new-wysiwyg-field', () => {
        window.tinyMCE.editors[this.removal_explainationTarget.id].on('click', () => {
          this.filterController._setRow(this.element);
        });
      });
    });
  }

  noFilteredTexts() {
    return true;
  }

  hasRemovalExplanation() {
    const editor = window.tinyMCE.editors[this.id];
    if (editor) {
      return editor.getContent().length > 0;
    }
    return this.removal_explainationTarget.innerHTML.length > 0;
  }

  setTextFromItem() {
    const editor = window.tinyMCE.editors[this.removal_explainationTarget.id];

    return {
      mode_of_removal: this.item.mode_of_removal,
      material: this.item.material,
      keywords: this.item.keywords,
      risk_class: this.item.risk_class,
      value: editor ? editor.getContent() : this.removal_explainationTarget.innerHTML,
    };
  }

  updateFieldInput(event) {
    const { target } = event;
    super.updateFieldInput(event);
    const attr = target.getAttribute(`data-${this.identifier}-target`);

    if (attr === 'mode_of_removal') this.setContainment(target.value);
    // this.updateRowHeight();
  }

  injectText(value) {
    const editor = window.tinyMCE.editors[this.removal_explainationTarget.id];
    if (editor) {
      editor.setContent(editor.getContent() + value);
    } else {
      window.onbeforeunload = function () { return ''; };
      this.removal_explainationTarget.innerHTML = this.removal_explainationTarget.innerHTML + value;
      const textarea = this.removal_explainationTarget.parentElement.querySelector('textarea');
      textarea.value += value;
    }
    // this.updateRowHeight();
  }

  updateRowHeight() {
    // this.afterFullLoad(() => {
    //   try {
    //     this.element.style.height = `${this.removal_explainationTarget.parentElement.offsetHeight + 100}px`;
    //   } catch {
    //     setTimeout(this.updateRowHeight, 100);
    //   }
    // });
  }

  setContainment(modeOfRemovalValue) {
    const isContainment = modeOfRemovalValue === 'Containment';
    this.containment_numberTarget.disabled = !isContainment;
    const numberValue = this.containment_numberTarget.value || 1;
    this.containment_numberTarget.value = isContainment ? numberValue : null;
  }
}
