import Controller from './../base_controller';

export default class extends Controller {
  static baseTargets = ['save'];

  initialize() {
    this.item = JSON.parse(this.data.get('item')) || {};
    this.type = this.identifier.replace('row-', '');
    this.showConditions = [this.noFilteredTexts];

    this.afterFullLoad(() => {
      if (this.hasSaveTarget) {
        this.saveModalController = this.getController(document.getElementById('save-standard-text-controller'), `save-standard-${this.type}-text`);
        this.text = this.setTextFromItem();
        this.showOrHideSave();
        setTimeout(() => {
          const wysiwygs = $(this.element).find('iframe');
          wysiwygs.each((index) => {
            wysiwygs[index].contentWindow.addEventListener('resize', () => {
              this.updateRowHeight();
            });
          });
        }, 100);
      }
      setTimeout(() => {
        this.filterController = this.findController(`filter-standard-${this.type}-text`);
      }, 0);
    });
  }

  injectText(value) {
    const valueIsObject = value.charAt(0) === '{';
    const injectTargetIsObject = this.injectTarget.value.charAt(0) === '{';

    if (valueIsObject && !injectTargetIsObject) {
      // standardText is object, but textarea in source is text
      throw Error('standard text is object, cannot input object into textarea');
    }
    if (!valueIsObject && !injectTargetIsObject) {
      this.injectTarget.value = this.injectTarget.value + value;
      this.updateRowHeight();
    }
  }

  updateRowHeight() {
    this.injectTarget.style.height = `${this.injectTarget.scrollHeight}px`;
    this.element.style.height = `${this.injectTarget.scrollHeight + 98}px`;
  }

  addShowCondition(condition) {
    this.showConditions = [...this.showConditions, condition];
  }

  save() {
    this.afterFullLoad(() => {
      this.saveModalController.setText(this.text);
      this.saveModalController.setModalVisibility('show');
    });
  }

  noFilteredTexts() {
    if (!this.filterController) return false;
    return this.filterController.current.length === 0;
  }

  showOrHideSave() {
    if (!this.hasSaveTarget) return;
    const visibility = this.showConditions
      .every(filterFn => filterFn.call(this)) ? 'visible' : 'hidden';
    this.saveTarget.style.visibility = visibility;
  }

  updateFieldInput({ target }) {
    const attr = target.getAttribute(`data-${this.identifier}-target`);
    this.item[attr] = target.value;

    this.filterController.filter();

    if (!this.hasSaveTarget) return;

    this.text = this.setTextFromItem();
    this.showOrHideSave();
  }
}
