import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu', 'button']

  connect() {
    useTransition(this, {
      element: this.menuTarget,
    });
  }

  toggle() {
    this.toggleTransition();
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.leave();
    }
  }

  enter() {
    const spaceToTop = this.buttonTarget.getBoundingClientRect().top;
    const spaceToBottom = window.innerHeight - this.buttonTarget.getBoundingClientRect().bottom;

    if (spaceToBottom < spaceToTop) {
      this.menuTarget.classList.add('top-dropdown');
      this.menuTarget.classList.add('has-been-top-dropdown');
    } else {
      this.menuTarget.classList.add('bottom-dropdown');
    }

    setTimeout(() => {
      const firstInput = this.menuTarget.querySelector('input[type="text"]');
      if (!firstInput) return;

      firstInput.select();
    }, 100);
  }

  leave() {
    this.menuTarget.classList.remove('top-dropdown');
    this.menuTarget.classList.remove('bottom-dropdown');
  }
}
